import { CONSTANTS } from "constants/Constants";
import oAxiosInstance from "redux/interceptors/Interceptors";

const CLIENTS_URL = CONSTANTS.API_URL + "clients";

/**
 * Funcion para tomar todos los clientes del sistema
 *
 * @param {number} nPage Numero de pagina que se intenta obtener
 * @param {number} nClientsPerPage Numero de clientes por paginas
 *
 * @author Leandro Curbelo
 */
export const GetClients = (nPage = 0, nClientsPerPage = 10, sSearch = "") => {
  return oAxiosInstance
    .get(CLIENTS_URL + "/list", {
      params: {
        skip: nPage,
        take: nClientsPerPage,
        search: sSearch,
      },
    })
    .then((oResult) => oResult);
}



export const GetClientsSend = (fDate = "") => {

  return oAxiosInstance
    .get(CLIENTS_URL + "/sendWhatsappChangeBeteries/"+fDate)
    .then((oResult) => oResult);
}



export const SendSelectedClients = (clientCodes) => {
  return oAxiosInstance
  .post(CLIENTS_URL + "/sendSelectedClients", { clientCodes })
  .then((oResult) => oResult.data);
 
};


/**
 * Función que retorna los clientes de forma reducida para optimizar busqueda
 * 
 * @param {string} sSearch Parametro para filtrar clientes
 * 
 * @author Leandro Curbelo
 */
export const GetClientsSuggestion = (sSearch = null) => {
  return oAxiosInstance.post(CLIENTS_URL + "/list/suggestion", { search: sSearch })
  .then((oResult) => oResult.data);

}
export const GetClientsBillingSuggestion = (sSearch = null) => {
  return oAxiosInstance.post(CLIENTS_URL + "/list/billing/suggestion", { search: sSearch })
  .then((oResult) => oResult.data);
}

export const GetClientsBilling = (
  nPage = 0,
  nClientsPerPage = 10,
  sSearch = ""
) => {
  return oAxiosInstance
    .get(CLIENTS_URL + "/list/billing", {
      params: {
        skip: nPage,
        take: nClientsPerPage,
        search: sSearch,
      },
    })
    .then((oResult) => oResult);
};

export const GetClientsFullList = () => {
  return oAxiosInstance
    .get(CLIENTS_URL + "/all", {})
    .then((oResult) => oResult);
};

export const GetClientsPerDate = (
  nPage = 0,
  nClientsPerPage = 10,
  sSearch = "",
  sFilterDate = "",
  dStartDate = null,
  dEndDate = null
) => {
  return oAxiosInstance
    .get(CLIENTS_URL, {
      params: {
        skip: nPage,
        take: nClientsPerPage,
        search: sSearch,
        filterDate: sFilterDate,
        startDate: dStartDate,
        endDate: dEndDate,
      },
    })
    .then((oResult) => oResult);
};

export const GetDebtorsClients = (nPage = 0, nClientsPerPage = 10, sSearch = "") => {
  return oAxiosInstance.get(CONSTANTS.API_URL + 'reports/debtors', { params: { skip: nPage, take: nClientsPerPage, sSearch: sSearch } }).then((oResult) => oResult);
}

export const GetClient = (nClientId) => {
  return oAxiosInstance
    .get(CLIENTS_URL + "/" + nClientId)
    .then((oResult) => oResult.data);
};

export const UnsubscribeClient = (nClientId, sReason, dDate, sObservation) => {
  return oAxiosInstance
    .post(CONSTANTS.API_URL + "unsubscriptions", {
      client_id: nClientId,
      reason: sReason,
      date: dDate,
      observation: sObservation
    })
    .then((oResult) => oResult.data);
};

export const UpdateUnsubscribeClient = (nUnsubscription, nClientId, sReason, dDate, sObservation) => {
  return oAxiosInstance
    .post(CONSTANTS.API_URL + "update/unsubscription", {
      unsubscription_id: nUnsubscription,
      client_id: nClientId,
      reason: sReason,
      date: dDate,
      observation: sObservation
    })
    .then((oResult) => oResult.data);
};

export const SaveClient = (oData) => {
  return oAxiosInstance
    .post(CLIENTS_URL, oData)
    .then((oResult) => oResult.data);
};

/**
 *
 * @param {*} oData
 */
export const UpdateClient = (oData) => {
  return oAxiosInstance
    .put(CLIENTS_URL + "/" + oData.id, oData)
    .then((oResult) => oResult.data);
};
/**
 *
 * @param {*} oData
 */
export const ActiveClient = (nClientId) => {
  return oAxiosInstance
    .get(CLIENTS_URL + "/active/" + nClientId)
    .then((oResult) => oResult.data);
};
/**
 *
 * @param {*} oData
 */
export const CancelRequest = (nClientId) => {
  return oAxiosInstance
    .get(CLIENTS_URL + "/cancelrequest/" + nClientId)
    .then((oResult) => oResult.data);
};

export const GetClientSinglePagination = (nIdClient, nItemIndex) => {
  return oAxiosInstance
    .get(CLIENTS_URL + "/single/Paginator", {
      params: {
        id: nIdClient,
        nItemIndex: nItemIndex,
      },
    })
    .then((oResult) => oResult);
};

/**
 *
 * @param {int} nfeeAmount
 */
export const GetAmountOfClientsByFee = (nfeeAmount, bMidesChangeFee) => {
  return oAxiosInstance
    .post(CLIENTS_URL + "/countFee", {
      nfeeAmount: nfeeAmount,
      bMidesChangeFee: bMidesChangeFee
    })
    .then((oResult) => oResult.data);
};

/**
 *
 * @param {int} nfeeAmount
 */
export const ChangeClientsFee = (nLastFee, nNextFee, bMidesChangeFee) => {
  return oAxiosInstance
    .post(CLIENTS_URL + "/changeFee", {
      nLastFee: nLastFee,
      nNextFee: nNextFee,
      bMidesChangeFee: bMidesChangeFee
    })
    .then((oResult) => oResult.data);
};

/**
 * Se generarn las cuotas para los clientes sin tarjeta
 */
export const GenerateClientsFees = () => {
  return oAxiosInstance
    .post(CLIENTS_URL + "/createUpdateFees")
    .then((oResult) => oResult.data);
};

/**
 * Se generarn las cuotas para los clientes con tarjeta
 */
export const GenerateClientsCardFees = () => {
  return oAxiosInstance
    .post(CLIENTS_URL + "/createUpdateCardFees")
    .then((oResult) => oResult.data);
};

export const GetDevicesShipped = (nPage = 0, nClientsPerPage = 10, sSearch = "") => {
  return oAxiosInstance.post(CONSTANTS.API_URL + 'reports/devicesShipped', { page: nPage, rows: nClientsPerPage, search: sSearch }).then((oResult) => oResult);
}

export const DeleteConyuge = (nClientId) => { return oAxiosInstance.post(CLIENTS_URL + "/deleteConyuge", { nClientId: nClientId }).then((oResult) => oResult.data); };

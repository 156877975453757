// Acciones globales que determinaran los valores de los reducers
export const CONSTANTS_ACTIONS = {
  // Actions para funciones compartidas o de un unico uso
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  MESSAGE: "MESSAGE",
  CLEAN_MESSAGE: "CLEAN_MESSAGE",
  SET_RESPONSE_CODE: "SET_RESPONSE_CODE",
  DESACTIVE_LOADING: "DESACTIVE_LOADING",
  ACTIVE_LOADING: "ACTIVE_LOADING",
  // Actions para funciones de clientes
  CLIENTS: "CLIENTS",
  // Actions para funciones de clientes
  CLIENTS_SEND: "CLIENTS_SEND",
  CLIENTS_SEND_RESULT: "CLIENTS_SEND_RESULT",
  // Actions para funciones de clientes
  CLIENTS_FULL_LIST: "CLIENTS_FULL_LIST",
  // Actions para obtener un Cliente
  CLIENT: "CLIENT",
  //Obtener el paginado simple de los clientes
  CLIENTS_SINGLE_PAGINATION: "CLIENTS_SINGLE_PAGINATION",
  // Actions para obtener un Cliente
  UNSUBSCRIBE_CLIENT: "UNSUBSCRIBE_CLIENT",
  // Actions para REGISTRAR ARCHIVADO DE CONTRATO
  CONTRACT_CLIENT: "CONTRACT_CLIENT",
  // Actions para Guardar cliente
  SAVE_CLIENTS: "SAVE_CLIENTS",
  // Actions para resetear listado de clientes
  RESET_CLIENTS: "RESET_CLIENTS",
  // Actions para resetear  cliente actual
  RESET_CLIENT: "RESET_CLIENT",
  // Actions para resetear  cliente actual
  CANCEL_REQUEST: "CANCEL_REQUEST",
  // Actions para obtener la cantidad de clientes con una cuota determinada
  CLIENTS_AMOUNT_BY_FEE: "CLIENTS_AMOUNT_BY_FEE",
  // Actions para ACTUALIZAR de CLIENTE
  UPDATE_CLIENT: "UPDATE_CLIENT",
  // Actions para setear la pagina del paginado del listado de CLIENTE
  SET_NAV_CURRENT_PAGE_CLIENTS: "SET_NAV_CURRENT_PAGE_CLIENTS",
  // Actions para setear la cantidad de items por pagina del paginado del listado de CLIENTE
  SET_NAV_ITEMS_PER_PAGE_CLIENTS: "SET_NAV_ITEMS_PER_PAGE_CLIENTS",
  // Actions para ACTIVAR DESACTIVAR de CLIENTE
  ACTIVE_CLIENT: "ACTIVE_CLIENT",
  // Limpiar aClients
  CLEAN_CLIENTS: "CLEAN_CLIENTS",
  //CLIENTS AUTOSUGGEST
  CLIENTS_AUTOSUGGEST: "CLIENTS_AUTOSUGGEST",
  //CLEAN CLIENTS AUTOSUGGEST
  CLEAN_CLIENTS_AUTOSUGGEST: "CLEAN_CLIENTS_AUTOSUGGEST",

  // Actions para funciones de NOTIFICACIONES
  NOTIFICATIONS: "NOTIFICATIONS",
  // Actions para funciones de NOTIFICACIONES
  NOTIFICATIONS_COUNT: "NOTIFICATIONS_COUNT",
  // Actions para resetear listado de LLAMADAS
  RESET_NOTIFICATIONS: "RESET_NOTIFICATIONS",

  // Actions para funciones de usuarios
  USERS: "USERS",
  // Actions para obtener un usuario
  USER: "USER",
  // Actions para Guardar cliente
  SAVE_USER: "SAVE_USER",
  // Actions para resetear listado de usuarios
  RESET_USERS: "RESET_USERS",
  // Actions para ACTUALIZAR de USUARIO
  UPDATE_USER: "UPDATE_USER",
  // Actions para eliminar de USUARIO
  DELETE_USER: "DELETE_USER",

  // Actions para funciones de LLAMADAS
  CALLS: "CALLS",
  CALLS_CLEAR: "CALLS_CLEAR",
  // Actions para funciones de LLAMADAS
  CALLSUMMARY: "CALLSUMMARY",
  CALLSUMMARY_CLEAR: "CALLSUMMARY_CLEAR",
  // Actions para obtener un LLAMADA
  CALL: "CALL",
  // Actions para Guardar LLAMADA
  SAVE_CALL: "SAVE_CALL",
  // Actions para actualizar LLAMADA
  UPDATE_CALL: "UPDATE_CALL",
  // Actions para resetear listado de LLAMADAS
  RESET_CALLS: "RESET_CALLS",
  // Actions que obtiene las llamadas que estan esperando ser atendiadas
  LISTENER_CALLS: "LISTENER_CALLS",
  // Actions que setea las llamadas notificadas al usuario
  NOTIFIED_CALLS: "NOTIFIED_CALLS",
  CALLS_INCOMING: "CALLS_INCOMING",

  // Actions para obtener listado de emergencias mobiles
  MOBILE_EMERGENCIES: "MOBILE_EMERGENCIES",
  // Actions para eliminar emergencias mobiles
  DELETE_MOBILE_EMERGENCY: "DELETE_MOBILE_EMERGENCY",
  // Actions para obtener listado de sociedades medicas
  MEDICAL_SOCIETIES: "MEDICAL_SOCIETIES",
  // Actions para eliminar SOCIEDAD MEDICA
  DELETE_MEDICAL_SOCIETY: "DELETE_MEDICAL_SOCIETY",

  // Actions para obtener departamentos de uruguay
  STATES: "STATES",

  // Actions para obtener LOS CORTES/INTERRUPCIONES
  INTERRUPTIONS: "INTERRUPTIONS",
  // Actions para gUADAR  CORTES/INTERRUPCIONES
  SAVE_INTERRUPTION: "SAVE_INTERRUPTION",
  // Actions para aCTUALIZAR  CORTES/INTERRUPCIONES
  UPDATE_INTERRUPTION: "UPDATE_INTERRUPTION",
  // Actions para obtener LAS RAZONES DE  CORTES/INTERRUPCIONES
  INTERRUPTION_REASONS: "INTERRUPTION_REASONS",
  // Actions para reset listado de interrupciones
  RESET_INTERRUPTIONS: "RESET_INTERRUPTIONS",

  // Actions para obtener LOS CORTES/INTERRUPCIONES
  RECEIPTS: "RECEIPTS",
  // Actions para gUADAR  CORTES/INTERRUPCIONES
  SAVE_RECEIPT: "SAVE_RECEIPT",
  // Actions para aCTUALIZAR  CORTES/INTERRUPCIONES
  UPDATE_RECEIPT: "UPDATE_RECEIPT",
  // Actions para reset listado de interrupciones
  RESET_RECEIPTS: "RESET_RECEIPTS",

  // Actions para obtener LOS PRESTAMOS
  LOANS: "LOANS",
  LOANS_REPORT: "LOANS_REPORT",
  CLEAN_LOANS: "CLEAN_LOANS",
  // Actions para gUADAR  PRESTAMOS
  SAVE_LOAN: "SAVE_LOAN",
  // Actions para aCTUALIZAR  PRESTAMOS
  UPDATE_LOAN: "UPDATE_LOAN",
  // Actions para activar|desactivar  PRESTAMOS
  ACTIVE_LOAN: "ACTIVE_LOAN",
  // Actions para reset listado de PRESTAMOS
  RESET_LOANS: "RESET_LOANS",

  // Actions para obtener LOS ENVIOS
  SHIPMENTS: "SHIPMENTS",
  // Actions para gUADAR  ENVIOS
  SAVE_SHIPPING: "SAVE_SHIPPING",
  // Actions para aCTUALIZAR  ENVIOS
  UPDATE_SHIPPING: "UPDATE_SHIPPING",
  // Actions para reset listado de ENVIOS
  RESET_SHIPMENTS: "RESET_SHIPMENTS",
  // Actions para reset listado de ENVIOS
  DEVOLUTION_SHIPMENT: "DEVOLUTION_SHIPMENT",
  // Action para limpiar reducer de aparatos enviados
  CLEAN_SHIPPMENTS: 'CLEAN_SHIPPMENTS',

  // Actions para obtener LOS ENVIOS
  RELATIONS: "RELATIONS",
  // Actions para gUADAR  ENVIOS
  SAVE_RELATION: "SAVE_RELATION",
  // Actions para aCTUALIZAR  ENVIOS
  UPDATE_RELATION: "UPDATE_RELATION",
  // Actions para reset listado de ENVIOS
  RESET_RELATIONS: "RESET_RELATIONS",

  // Actions para obtener departamentos de uruguay
  CITIES: "CITIES",
  // Actions para obtener listado de aparatos
  DEVICES: "DEVICES",
  // Actions para obtener listado de tipos de recibo
  VOUCHER_TYPES: "VOUCHER_TYPES",
  // Actions para eliminar aparato
  DELETE_DEVICE: "DELETE_DEVICE",

  // Actions obtener detalle de pagos por cliente
  PAYMENT_DETAILS: "PAYMENT_DETAILS",
  PAYMENT_IMPORT: "PAYMENT_IMPORT",
  UPDATE_CLIENTPAYMENTS: "UPDATE_CLIENTPAYMENTS",
  PAYMENT_INCREMENT_ITERATION: "PAYMENT_INCREMENT_ITERATION",
  PAYMENT_INCREMENT_ITERATION_CLEAN: "PAYMENT_INCREMENT_ITERATION_CLEAN",
  PAYMENT_CLEAN: "PAYMENT_CLEAN",
  PAYMENT_INCREMENT_ITERATION_ERRORS: "PAYMENT_INCREMENT_ITERATION_ERRORS",
  PAYMENT_INCREMENT_ITERATION_ERRORS_CLEAN: "PAYMENT_INCREMENT_ITERATION_ERRORS_CLEAN",
  PAYMENT_ERRORS: "PAYMENT_ERRORS",
  PAYMENT_ERRORS_CLEAN: "PAYMENT_ERRORS_CLEAN",

  // Obtener las cuotas por cliente en un rango de 12 meses anteriores al mes y año pasado por parametro
  FEE_GET_BY_CLIENT_AND_RANGE: "FEE_GET_BY_CLIENT_AND_RANGE",

  //Actions de reportes
  BATTERY_CHANGES: "BATTERY_CHANGES",
  BIRTHDAY: "BIRTHDAY",
  DEATHS: "DEATHS",
  DEBTORS: "DEBTORS",
  DEVICES_SHIPPED: "DEVICES_SHIPPED",
  LOAN: "LOAN",
  NEW_REVENUE: "NEW_REVENUE",
  TIME_WITHOUT_CALLING: "TIME_WITHOUT_CALLING",
  UNSUBSCRIBE_REQUEST: "UNSUBSCRIBE_REQUEST",
  CLEAN_REPORTS: "CLEAN_REPORTS",

  PROGRESSBAR_UPDATE: "PROGRESSBAR_UPDATE",
  PROGRESSBAR_SHOW: "PROGRESSBAR_SHOW",
  PROGRESSBAR_HIDE: "PROGRESSBAR_HIDE",
  PROGRESSBAR_SET_TOTAL: "PROGRESSBAR_SET_TOTAL",
  PROGRESSBAR_PROGRESS: "PROGRESSBAR_PROGRESS",

  NAVIGATE_TO_BOARD: 'NAVIGATE_TO_BOARD',
  NAVIGATE_CLEAN: 'NAVIGATE_CLEAN',
  NAVIGATE_TO_CLIENTS: 'NAVIGATE_TO_CLIENTS',
  NAVIGATE_CLIENTS_CLEAN: 'NAVIGATE_CLIENTS_CLEAN',
  NAVIGATE_TO_BILLING: 'NAVIGATE_TO_BILLING',
  NAVIGATE_BILLING_CLEAN: 'NAVIGATE_BILLING_CLEAN',
  CLEAN_CALL_NAVIGATION: 'CLEAN_CALL_NAVIGATION',
};

import React, { Component } from 'react';
import { Grid, Card, Form, Button } from 'tabler-react';
import { connect } from 'react-redux';
import { actionGetClientsSend,actionSendSelectedClients } from 'redux/actions/ClientsActions';


class ClientSend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: '',  // Fecha seleccionada
      selectedClients: [], // Almacena los clientes seleccionados
    };
  }

  // Manejar el cambio en el campo de fecha
  handleDateChange = (event) => {
    this.setState({ selectedDate: event.target.value });
  };

  // Manejar la consulta
  handleConsult = () => {
    const { selectedDate } = this.state;
    this.props.get(selectedDate); // Llamar a la acción de Redux para obtener los datos
  };

  // Manejar la selección de clientes
  handleCheckboxChange = (clientCode) => {
    this.setState((prevState) => {
      const { selectedClients } = prevState;
      if (selectedClients.includes(clientCode)) {
        return { selectedClients: selectedClients.filter((code) => code !== clientCode) };
      } else {
        return { selectedClients: [...selectedClients, clientCode] };
      }
    });
  };

  // Enviar los clientes seleccionados
  /*handleSendClients = () => {
    const { selectedClients } = this.state;
    if (selectedClients.length > 0) {
      this.props.sendSelectedClients(selectedClients);
    
      // Aquí puedes agregar una acción o un fetch para enviar la lista seleccionada
    } else {
      alert('Por favor selecciona al menos un cliente.');
    }
  }; */

  handleSendClients = () => {
    const { selectedClients, selectedDate } = this.state; // Agregar fecha seleccionada
    
    if (selectedClients.length > 0) {
      this.props.sendSelectedClients(selectedClients); // Acción para enviar los mensajes
  
      // Limpiar la selección de clientes
      this.setState({ selectedClients: [] }, () => {
        // Actualizar el listado de clientes después del envío
        this.props.get(selectedDate); // Llama a la acción de Redux para refrescar el listado
      });
    } else {
      alert('Por favor selecciona al menos un cliente.');
    }
  };

  render() { 
    const { selectedDate, selectedClients } = this.state;
    const { clientsData } = this.props;

    return (
      <Card>
        <Card.Header>
          <Card.Title>Formulario de Consulta Cambios Baterías</Card.Title>
        </Card.Header>
        <Card.Body>
          {/* Fecha y botón consultar */}
          <Grid.Row>
            <Grid.Col width={12}>
              <Form>
                <Form.Group style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <div>
                    <Form.Label>
                      Selecciona una fecha (La búsqueda se realizará en el año hasta la fecha ingresada)
                    </Form.Label>
                    <Form.Input
                      type="date"
                      value={selectedDate}
                      onChange={this.handleDateChange}
                      className="w-25"
                    />
                  </div>

                  <Button type="button" color="primary" onClick={this.handleConsult}>
                    Consultar
                  </Button>
                </Form.Group>
              </Form>
            </Grid.Col>
          </Grid.Row>

          {/* Tabla con los datos de los clientes */}
          <Grid.Row style={{ marginTop: '20px' }}>
            <Grid.Col width={12}>
              <Card>
                <Card.Body>
                  <h4>Lista de Clientes</h4>
                  <table cellPadding="10" style={{ width: '100%', marginTop: '20px' }}>
                    <thead>
                      <tr>
                        
                        <th>Numero socio</th>
                        <th>Nombre</th>
                        <th>Apellido</th>
                        <th>Modelo Dispositivo</th>
                        <th>Fecha último cambio batería</th>
                        <th>WhatsApp notificación cambio batería</th>
                        <th>Seleccionar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientsData && clientsData.length > 0 ? (
                        clientsData.map((client) => (
                          <tr key={client.code}>
                           
                            <td>{client.code}</td>
                            <td>{client.name} {client.secondname || ''}</td>
                            <td>{client.lastname} {client.secondsurname || ''}</td>
                            <td>{client.device_model_name}</td>
                            <td>{client.changebatterybracelet}</td>
                            <td>{client.notification_change_battery || 'Sin Datos'}</td>
                            <td>
                            <input
                              type="checkbox"
                              checked={selectedClients.includes(client.code)}
                              onChange={() => this.handleCheckboxChange(client.code)}
                              disabled={!client.notification_change_battery || client.notification_change_battery.trim() === ''} // Deshabilitar si no hay teléfono
                            />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7">No hay clientes disponibles</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>

          {/* Botón Enviar */}
          <Grid.Row style={{ marginTop: '20px' }}>
            <Grid.Col width={12} style={{ textAlign: 'right' }}>
            <Button 
                color="success" 
                onClick={this.handleSendClients} 
                disabled={this.state.selectedClients.length === 0} // Deshabilitar si no hay seleccionados
              >
                Enviar Mensajes
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    );
  }
}

// Mapear el estado de Redux a las propiedades del componente
const mapStateToProps = (state) => ({
  clientsData: state.clientsReducer.aClientsSend, 
});

// Mapear las acciones de Redux
const mapDispatchToProps = (dispatch) => ({
  sendSelectedClients: (clientCodes) => dispatch(actionSendSelectedClients(clientCodes)),
  get: (date) => {
    dispatch(actionGetClientsSend(date)); // Llamar a la acción Redux con la fecha seleccionada
  },
 
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientSend);

import React, { Component } from "react";
import { Card, Button, Grid, Form, Icon } from "tabler-react";
import { UpdatePageTitle } from "components/UpdatePageTitle";
import { connect } from "react-redux";
import {
  actionGetClient,
  actionResetClients,
  actionSaveClient,
  actionUpdateClient,
  actionGetClients,
  actionCleanClient,
} from "redux/actions/ClientsActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faHome,
  faMobileAlt,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { actionGetStates } from "redux/actions/StatesActions";
import { actionGetCities } from "redux/actions/CitiesActions";
import {
  actionDeleteDevice,
  actionGetDevices,
} from "redux/actions/DevicesActions";
import Autosuggest from "react-autosuggest";
import { actionGetMobileEmergencies } from "redux/actions/MobileEmergenciesActions";
import { actionGetMedicalSocieties } from "redux/actions/MedicalSocietiesActions";
import profile from "assets/svg/profile.svg";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import Modal from "components/Modal";
import { Helpers } from "helpers/Helpers";
import { CONSTANTS } from "constants/Constants";
import { nextDay } from "date-fns";
import { text } from "@fortawesome/fontawesome-svg-core";
import { th } from "date-fns/locale";

class ClientUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oClient: {},
      oPartner: {},
      oClientEmpty: {
        id: null,
        identificationcard: "",
        code: "",
        rut: "",
        name: "",
        secondname: "",
        lastname: "",
        secondsurname: "",
        affiliationdate: Helpers.ConvertTZ(new Date()),
        birthdate: "",
        sex: "Mujer",
        feevalue: 0,
        midespartner: null,
        mides: false,
        business: false,
        contacts: "",
        show_medicines: "",
        show_medical_record: "",
        devicephone: "",
        observation: "",
        contractobservation: "",
        contract: false,
        active: true,
        company: {
          rut: "",
          name: "",
          domicile: "",
        },
        medicalSociety: "",
        mobileEmergency: "",
        show_private_doctor: "",
        show_device: "",
        pay_method: "",
        change_battery_bracelet: Helpers.ConvertTZ(new Date()),
        change_battery: Helpers.ConvertTZ(new Date()).getFullYear(),
        addresses: [],
        phones: [],
        emails: [],
        spouse: null,
        contracted_devices: [],
      },
      bValidForm: false,
      oErrors: {
        identificationcard: "",
        rut: "",
        name: "",
        secondname: "",
        lastname: "",
        secondsurname: "",
        email: [],
        affiliationdate: "",
        birthdate: "",
        sex: "",
        mides: 0, //no aplica
        midespartner: null,
        contacts: "",
        devicephone: "",
        observation: "",
        active: true,
        phones: [],
        emails: [],
        company: {
          name: "",
          rut: "",
          domicile: "",
        },
        privateDoctors: [],
      },
      // Control de errores para formulario de partner
      oPartnerErrors: {
        identificationcard: "",
        rut: "",
        name: "",
        secondname: "",
        lastname: "",
        secondsurname: "",
        email: [],
        affiliationdate: "",
        birthdate: "",
        sex: "",
        mides: 0, //no aplica
        midespartner: "",
        contacts: "",
        devicephone: "",
        observation: "",
        active: true,
        phones: [],
        emails: [],
        privateDoctors: [],
      },
      department: "",
      city: "",
      aCities: props.oCities.aCities,
      address: "",
      corner: "",
      zipcode: "",
      addressesList: [],
      email: "",
      emailsList: [],

      companyName: "",
      companyRut: "",
      companyDomicile: "",

      phone: "",
      phoneType: "Celular",
      notification_change_battery:"",
      phonesList: [],
      private_doctorList: [],

      nPage: 1,

      lDeviceSuggestions: [],
      oSelectedDevice: "",
      lMobileEmergencySuggestions: [],
      oSelectedMobileEmergency: "",
      lMedicalSocietySuggestions: [],
      oSelectedMedicalSociety: "",

      mides: 0,
      partner: 0,

      aDevicesList: [],
      indexOfDeviceToDelete: null,
      isModalConfirmDeleteDeviceOpen: false,
      isModalConfirmDeleteDeviceModelOpen: false,
      nDeviceModelDeleteId: null,
      bEditAddress: false,
      nEditIndex: null,
      sDomicileEdit: "",
      sCornerEdit: "",
      sStateEdit: { id: null, name: "" },
      sCityEdit: { id: null, name: "" },
      sZipCodeEdit: "",
      checked: null,
    };
    this.props.getStates();
    this.props.getCities(1);
    this.props.getDevices();
    this.props.getMobileEmergencies();
    this.props.getMedicalSocieties();
  }

  componentDidMount = () => {
    if (!this.props.match.params.id) {
      this.setClientEmpty();
    } else {
      this.props.get(this.props.match.params.id);
    }
  };

  componentDidUpdate = () => {
    if (this.props.match.params.id) {
      if (
        this.props.propsClient.oClient !== this.state.oClient &&
        this.props.propsClient.oClient
      )
        if (
          !this.state.oClient ||
          this.props.propsClient.oClient.id !== this.state.oClient.id
        )
          this.setClient();
    }
  };

  componentWillUnmount = () => {
    this.setState({ oClient: null });
    this.props.cleanClient();
  };

  setClientEmpty = () => {
    let oClient = JSON.parse(JSON.stringify(this.state.oClientEmpty));
    oClient.affiliationdate = Helpers.DateNowTz();
    oClient.change_battery_bracelet = Helpers.DateNowTz();
    oClient.change_battery = Helpers.DateNowTz().getFullYear();
    this.setState({ oClient });
  };

  setClient = async () => {
    if (!this.props.match.params.id) return await this.setClientEmpty();
    let { oClient } = this.props.propsClient;
    if (oClient === null) return this.setState({ oClient: null });
    if (oClient.spouse !== undefined) {
      if (oClient.spouse !== null) {
        this.setState({ partner: 1 });
        let oPartner = { ...oClient.spouse };
        // Casteo de fechas para el manejo mediante DatePicker
        oPartner.birthdate = oPartner.birthdate
          ? Helpers.GetDateObject(oPartner.birthdate)
          : "";
        oPartner.affiliationdate = Helpers.GetDateObject(
          oPartner.affiliationdate
        );
        oPartner.change_battery_bracelet = oPartner.change_battery_bracelet
          ? Helpers.GetDateObject(oPartner.change_battery_bracelet)
          : "";
        oPartner.business = false;
        if (oPartner.company) {
          oPartner.business = true;
          this.setState({
            companyName: oPartner.company.name,
            companyRut: oPartner.company.rut,
            companyDomicile: oPartner.company.domicile,
          });
        }
        this.setState({ oPartner });
      } else this.setState({ partner: 0 });
    } else this.setState({ partner: 0 });
    if (oClient.midespartner) this.setState({ mides: 1 });
    else this.setState({ mides: 0 });
    // Casteo de fechas para el manejo mediante DatePicker
    oClient.birthdate = oClient.birthdate
      ? Helpers.GetDateObject(oClient.birthdate)
      : "";
    oClient.affiliationdate = Helpers.GetDateObject(oClient.affiliationdate);
    oClient.change_battery_bracelet = oClient.change_battery_bracelet
      ? Helpers.GetDateObject(oClient.change_battery_bracelet)
      : "";
    if (oClient.company) {
      oClient.business = true;
      let company = oClient.company;
      this.setState({
        companyName: company.name,
        companyRut: company.rut,
        companyDomicile: company.domicile,
      });
    }
    this.setState({
      oClient: oClient,
      aDevicesList: oClient.contracted_devices,
      oSelectedMedicalSociety: oClient.medicalSociety || "",
      oSelectedMobileEmergency: oClient.mobileEmergency || "",
      oSelectedDevice: oClient.show_device || "",
    });
  };

  _handlePhoneTypeChange = async (event) => {
    const { value } = event.target;
    if (this.state.nPage === 1) await this.setState({ phoneType: value });
    else await this.setState({ phoneType: value });
  };

  _handleChangeFeevalue = (event) => {
    const { name, value } = event.target;
    let oClient =
      this.state.nPage === 1
        ? { ...this.state.oClient }
        : { ...this.state.oPartner };
    let oErrors =
      this.state.nPage === 1 ? this.state.oErrors : this.state.oPartnerErrors;
    if (!isNaN(value)) {
      oClient[name] = value;
      this.saveChange(oClient, oErrors);
    } else {
      oClient[name] = "";
      this.saveChange(oClient, oErrors);
    }
  };

  _handleSaveCompany = async (event) => {
    const { name, value } = event.target;
    let oTempClient =
      this.state.nPage === 1
        ? { ...this.state.oClient }
        : { ...this.state.oPartner };
    let oCompany = oTempClient.company;
    switch (name) {
      case "companyName":
        this.setState({ companyName: value });
        oCompany.name = value;
        break;
      case "companyRut":
        this.setState({ companyRut: value });
        oCompany.rut = value;
        break;
      case "companyDomicile":
        this.setState({ companyDomicile: value });
        oCompany.domicile = value;
        break;
      default:
        break;
    }
    oTempClient.company = oCompany;
    this.saveChange(oTempClient);
  };

  _handleChange = (event) => {
    const { name, value } = event.target;
    let oUser;
    if (this.state.nPage === 1) oUser = { ...this.state.oClient };
    else oUser = { ...this.state.oPartner };
    let oErrors =
      this.state.nPage === 1 ? this.state.oErrors : this.state.oPartnerErrors;
    oUser[name] = value;
    this.saveChange(oUser, oErrors);
  };

  _handlePrimaryAddress = (event) => {
    let { checked } = this.state;
    const { value } = event.target;
    this.setState({ checked: value });

  };

  saveChange = async (oData, oErrors = null) => {
    if (this.state.nPage === 1) {
      let oClient = { ...oData };
      this.setState({ oClient });
      if (oErrors !== null) {
        this.setState({ oErrors: oErrors });
      }
    } else {
      let oPartner = oData;
      this.setState({ oPartner });
      if (oErrors !== null) {
        this.setState({ oPartnerErrors: oErrors });
      }
    }
  };

  _handleCheckedChange = async (event) => {
    let { name, checked } = event.target;
    if (name === "partner") {
      let oPartner = JSON.parse(JSON.stringify(this.state.oClient));
      oPartner.identificationcard = "";
      oPartner.name = "";
      oPartner.secondname = "";
      oPartner.lastname = "";
      oPartner.secondsurname = "";
      oPartner.show_medicines = "";
      oPartner.show_medical_record = "";
      oPartner.show_private_doctor = "";
      oPartner.contacts = this.state.oClient.contacts;
      oPartner.observation = "";
      oPartner.emails.length = 0;
      oPartner.sex = "Mujer";
      oPartner.affiliationdate = this.state.oClient.affiliationdate;
      oPartner.birthdate = "";
      oPartner.phones.length = 0;
      oPartner.phone = "";
      oPartner.principal_phone = this.state.oClient.principal_phone;
      oPartner.medicalSociety = "";
      oPartner.mobileEmergency = "";
      oPartner.devicephone = this.state.oClient.devicephone;
      oPartner.show_device = this.state.oClient.show_device;
      oPartner.change_battery = this.state.oClient.change_battery;
      oPartner.change_battery_bracelet =
        this.state.oClient.change_battery_bracelet;
      oPartner.addresses = this.state.oClient.addresses;
      this.setState({ partner: checked ? 1 : 0 });
      await this.setState({ oPartner });
      this.setState({ nPage: 1 });
    } else {
      let oClientTemp =
        this.state.nPage === 1
          ? { ...this.state.oClient }
          : { ...this.state.oPartner };
      if (name === "business") {
        if (checked === true)
          oClientTemp.company = { name: "", rut: "", domicile: "" };
        else oClientTemp.company = null;
      }
      if (name === "mides") {
        if (checked === false) oClientTemp.midespartner = null;
        else oClientTemp.midespartner = "0";
        this.setState({ mides: checked ? 1 : 0 });
      }
      oClientTemp[name] = checked === true || checked === 1 ? 1 : 0;
      await this.saveChange(oClientTemp);
    }
  };

  formatDate = (sDate) => {
    if (sDate instanceof Date) return sDate;
    if (
      sDate !== null &&
      sDate !== "" &&
      sDate !== undefined &&
      sDate !== "01-01-N/D"
    ) {
      let aDate = sDate.split("-");
      if (aDate.length === 3) {
        let oDate = Helpers.ConvertTZ(
          new Date(aDate[0], parseInt(aDate[1]) - 1, aDate[2])
        );
        return oDate;
      }
      return Helpers.ConvertTZ(
        new Date(aDate[0], parseInt(aDate[1]) - 1, aDate[2])
      );
    }
    return Helpers.ConvertTZ(new Date());
  };

  _handleBirthDateChange = (selectedDate) => {
    let oClientTemp =
      this.state.nPage === 1
        ? { ...this.state.oClient }
        : { ...this.state.oPartner };
    oClientTemp.birthdate = selectedDate;
    this.saveChange(oClientTemp);
  };

  _handleBatteryBraceletChange = (selectedDate) => {
    let oClientTemp =
      this.state.nPage === 1
        ? { ...this.state.oClient }
        : { ...this.state.oPartner };
    oClientTemp.change_battery_bracelet = selectedDate;
    this.saveChange(oClientTemp);
  };
  _handleBatteryDeviceChange = (selectedDate) => {
    let oClientTemp =
      this.state.nPage === 1 ? this.state.oClient : this.state.oPartner;
    oClientTemp.change_battery = selectedDate;
    this.saveChange(oClientTemp);
  };

  _handleAffiliationDateChange = (selectedDate) => {
    let oClientTemp =
      this.state.nPage === 1 ? this.state.oClient : this.state.oPartner;
    oClientTemp.affiliationdate = selectedDate;
    this.saveChange(oClientTemp);
  };

  _handleChangeState = (event) => {
    const { value } = event.target;
    let department = "";
    if (value !== "statevacio") {
      department = JSON.parse(value);
      this.setState({ department });
      this.props.getCities(department.id);
      this.setState({ aCities: this.props.oCities.aCities });
    } else this.setState({ department: "" });
  };

  _handleChangeCity = (event) => {
    let { value } = event.target;
    let city = "";
    if (value !== "cityvacio") {
      city = JSON.parse(value);
      let zipcode = city.zipcode;
      this.setState({ city, zipcode: zipcode });
    } else this.setState({ city: "", zipcode: "" });
  };

  _handleChangeStateEdit = (oEvent) => {
    const { value: nValue } = oEvent.target;
    const { aStates } = this.props.oStates;
    aStates.map((oState) => {
      if (oState.id == nValue) {
        this.props.getCities(oState.id);
        this.setState({
          sStateEdit: oState,
          sCityEdit: { id: null, name: "" },
          sZipCodeEdit: "",
        });
      }
    });
  };

  _handleChangeCityEdit = (oEvent) => {
    const { value: nValue } = oEvent.target;
    if (nValue !== "cityEditVacio") {
      const { aCities } = this.props.oCities;
      aCities.map((oCity) => {
        if (oCity.id == nValue)
          this.setState({ sCityEdit: oCity, sZipCodeEdit: oCity.zipcode });
      });
    } else
      this.setState({ sCityEdit: { id: null, name: "" }, sZipCodeEdit: "" });
  };

  _handleChangeAddress = (event) => {
    let { value } = event.target;
    this.setState({ address: value });
  };

  _handleChangeCorner = (event) => {
    let { value } = event.target;
    this.setState({ corner: value });
  };

  _handleChangeZipCode = (event) => {
    let { value } = event.target;
    this.setState({ zipcode: value });
  };

  _handleAddAddress = async () => {
    let oClientTemp =
      this.state.nPage === 1
        ? { ...this.state.oClient }
        : { ...this.state.oPartner };
    oClientTemp.addresses.push({
      city: {
        id: this.state.city.id,
        name: this.state.city.name,
        zipcode: this.state.city.zipcode,
      },
      state: { id: this.state.department.id, name: this.state.department.name },
      domicile: this.state.address,
      corner1: this.state.corner,
      type: this.state.checked,
    });
    this.saveChange(oClientTemp);
    let oComboStates = document.getElementsByName("states");
    let oComboCities = document.getElementsByName("cities");
    oComboStates[0].value = "statevacio";
    oComboCities[0].value = "cityvacio";
    this.setState({
      address: "",
      zipcode: "",
      corner: "",
      department: "",
      city: "",
      zipcode: "",
    });
  };

  _handleRemoveAddress = (index) => {
    let oClientTemp =
      this.state.nPage === 1 ? this.state.oClient : this.state.oPartner;
    oClientTemp.addresses.splice(index, 1);
    this.saveChange(oClientTemp);
  };

  _handleEditAddress = (index, oElement) => {
    if (oElement.state && oElement.state.id) {
      this.props.getCities(oElement.state.id);
      this.setState({ sStateEdit: oElement.state });
      if (oElement.city && oElement.city.zipcode) {
        this.setState({
          sCityEdit: oElement.city,
          sZipCodeEdit: oElement.city.zipcode,
        });
      }
    }
    this.setState({
      bEditAddress: true,
      nEditIndex: index,
      sDomicileEdit: oElement.domicile,
      sCornerEdit: oElement.corner1,
    });
  };

  _handleInputAddressEdit = (oEvent) => {
    let { value, name } = oEvent.target;
    this.setState({ [name]: value });
  };

  _handleConfirmEditAddress = (nIndex) => {
    const { sDomicileEdit, sCornerEdit, sCityEdit, sStateEdit } = this.state;
    let oClientTemp =
      this.state.nPage === 1 ? this.state.oClient : this.state.oPartner;
    let oAddressEdit = oClientTemp.addresses[nIndex];
    oAddressEdit.domicile = sDomicileEdit;
    oAddressEdit.corner1 = sCornerEdit;
    oAddressEdit.city = sCityEdit;
    oAddressEdit.state = sStateEdit;
    oAddressEdit.type = this.state.checked;
    this.saveChange(oClientTemp);
    this.setState({
      bEditAddress: false,
      nEditIndex: null,
      sDomicileEdit: "",
      sCornerEdit: "",
      sCityEdit: { id: null, name: "" },
      sStateEdit: { id: null, name: "" },
      sZipCodeEdit: "",
    });
  };

  _handleAddPhone = async () => {
    //TODO aqui lleva validacion de numero de telefono
    if (this.state.phone !== "") {
      let phones;
      let oUser;
      if (this.state.nPage === 1) {
        oUser = this.state.oClient;
        if (oUser.phones) phones = oUser.phones;
        else {
          oUser.phones = [];
          phones = oUser.phones;
        }
      } else {
        oUser = this.state.oPartner;
        if (oUser.phones) phones = oUser.phones;
        else {
          oUser.phones = [];
          phones = oUser.phones;
        }
      }
      phones.push({
        phonenumber: this.state.phone,
        type: this.state.phoneType,
      });
      oUser.phones = phones;
      await this.saveChange(oUser);
      this.setState({ phone: "", phoneType: "Celular" });
    }
  };

  _handleRemovePhone = (index) => {
    let oClientTemp =
      this.state.nPage === 1
        ? { ...this.state.oClient }
        : { ...this.state.oPartner };
    oClientTemp.phones.splice(index, 1);
    this.saveChange(oClientTemp);
  };

  _handleAddEmail = () => {
    //TODO aqui lleva validacion de email
    if (this.state.email !== "" || this.state.oPartnerEmail !== "") {
      let oClientTemp =
        this.state.nPage === 1
          ? { ...this.state.oClient }
          : { ...this.state.oPartner };
      oClientTemp.emails.push({ email: this.state.email });
      this.saveChange(oClientTemp);
      this.setState({ email: "" });
    }
  };

  _handleRemoveEmail = (index) => {
    let oClientTemp =
      this.state.nPage === 1
        ? { ...this.state.oClient }
        : { ...this.state.oPartner };
    oClientTemp.emails.splice(index, 1);
    this.saveChange(oClientTemp);
  };

  _save = async () => {
    // Se obtienen los datos del cliente
    let oClient = this.state.oClient;
    let i = this.state.checked;

    if (i === null && !oClient.id && oClient.addresses.length > 0) {
      return alert("No selecciono una nueva direccion principal");
    }

    if (i === null && oClient.id != null && oClient.addresses.length > 0) {
      const res = [];
      oClient.addresses.forEach(function (address, index, array) {
        if (address.domicile && address.type === "principal") {
          res.push(1);
        }
      });

      if (res.length < 1) {
        return alert("Debe elegir una nueva direccion principal");
      }
    }

    // # Se elige otra direccion princ
    if (i != null && oClient.id != null) {
      oClient.addresses.forEach(function (address, index, array) {
        address.type = null;
      });

      oClient.addresses[i].type = "principal";
    }

    // # Se elege address Princ cuando se crea new client
    if (i != null && !oClient.id) {
      oClient.addresses[i].type = "principal";
    }

    if (oClient.pay_method === "Facilpago") {
      if (!oClient.card_branch || !oClient.card_number) {
        return alert("Debe ingresar los datos de la tarjeta de crédito");
      }
    } else if (oClient.pay_method !== "Facilpago") {
      oClient.card_branch = null;
      oClient.card_number = null;
      this.setState({ oClient });
    }

    //console.log('Siguioooo _> ', oClient)

    oClient.contracted_devices = this.state.aDevicesList;
    if (this.state.partner) {
      // Se ingresan los datos de los telefonos del conyuge
      let oPartner = this.state.oPartner;
      let oClient = this.state.oClient;
      oClient.spouse = oPartner;
      this.setState({ oClient });
    }
    if (oClient.id === null) return this.props.save(oClient);
    return this.props.update(oClient);
  };

  _updateCity = () => {
    this.setState({ city: this.props.oCities.aCities[0] });
  };

  changePage = async () => {
    let oPartner = this.state.oPartner,
      nPage = this.state.nPage !== 1 ? 1 : 2;
    if (nPage !== 1 && oPartner !== {}) {
      this.setState({
        oPartner: oPartner,
        nPage: nPage,
      });
    } else this.setState({ nPage: nPage });
  };

  downloadClientFile = async () => {
    const { oClient, oPartner, nPage } = this.state;
    let nClientId;
    if (nPage === 1) nClientId = oClient.id;
    else nClientId = oPartner.id;
    window.open(
      `${CONSTANTS.API_URL}clients/downloadClientFile/${nClientId}`,
      "_blank"
    );
  };

  onChangeDoctorData = (oEvent) => {
    const { name, value } = oEvent.target;
    switch (name) {
      case "privateDoctorName":
        let privateDoctor = this.state.private_doctorList + value;
        if (this.state.nPage === 1)
          this.setState({ private_doctorList: [{ name: privateDoctor }] });
        else
          this.setState({ oPartnerPrivateDoctors: [{ name: privateDoctor }] });
        break;
      default:
        break;
    }
  };

  renderMobileEmergencySuggestion = (oMobileEmergencySuggestion) => (
    <div className="suggestion-item">
      <span>{oMobileEmergencySuggestion.name}</span>
    </div>
  );

  // Use your imagination to render suggestions.
  renderMedicalSocietySuggestion = (oMedicalSocietySuggestion) => (
    <div className="suggestion-item">
      <span>{oMedicalSocietySuggestion.name}</span>
    </div>
  );

  // Funcion que se ejecuta cada vez que cambia el valor entrado en el input de dispositivos
  onChangeDevice = (event, { newValue }) => {
    this.setState({
      oSelectedDevice: newValue,
    });
    let oClientTemp =
      this.state.nPage === 1
        ? { ...this.state.oClient }
        : { ...this.state.oPartner };
    oClientTemp.show_device = newValue;
    this.saveChange(oClientTemp);
  };
  // Funcion que se ejecuta cada vez que cambia el valor entrado en el input de emergencia movil
  onChangeMobileEmergency = (event, { newValue }) => {
    this.setState({
      oSelectedMobileEmergency: newValue,
    });
    let oClientTemp =
      this.state.nPage === 1
        ? { ...this.state.oClient }
        : { ...this.state.oPartner };
    oClientTemp.mobileEmergency = newValue;
    this.saveChange(oClientTemp);
  };
  // Funcion que se ejecuta cada vez que cambia el valor entrado en el input de emergencia movil
  onChangeMedicalSociety = (event, { newValue }) => {
    this.setState({
      oSelectedMedicalSociety: newValue,
    });
    let oClientTemp =
      this.state.nPage === 1
        ? { ...this.state.oClient }
        : { ...this.state.oPartner };
    oClientTemp.medicalSociety = newValue;
    this.saveChange(oClientTemp);
  };
  // Funcion que filtra la lista de dispositivos y devuelve los sugeridos segun el valor introducido
  onDeviceSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const { aDevices } = this.props.oDevices;
    const lDeviceSuggestions =
      inputLength === 0
        ? []
        : aDevices.filter(
            (oDevice) =>
              oDevice.name.toLowerCase().slice(0, inputLength) === inputValue
          );
    this.setState({ lDeviceSuggestions });
  };
  // Funcion que filtra la lista de emergencia movil y devuelve los sugeridos segun el valor introducido
  onMobileEmergencySuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const { aMobileEmergencies } = this.props.oMobileEmergencies;
    const lMobileEmergencySuggestions =
      inputLength === 0
        ? []
        : aMobileEmergencies.filter(
            (oMobileEmergency) =>
              oMobileEmergency.name.toLowerCase().slice(0, inputLength) ===
              inputValue
          );
    this.setState({ lMobileEmergencySuggestions });
  };
  // Funcion que filtra la lista de emergencia movil y devuelve los sugeridos segun el valor introducido
  onMedicalSocietySuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const { aMedicalSocieties } = this.props.oMedicalSocieties;
    const lMedicalSocietySuggestions =
      inputLength === 0
        ? []
        : aMedicalSocieties.filter(
            (oMedicalSociety) =>
              oMedicalSociety.name.toLowerCase().slice(0, inputLength) ===
              inputValue
          );
    this.setState({ lMedicalSocietySuggestions });
  };
  // Autosuggest will call this function every time you need to clear suggestions.
  onDeviceSuggestionsClearRequested = () => {
    this.setState({ lDeviceSuggestions: [] });
  };
  // Autosuggest will call this function every time you need to clear suggestions.
  onMobileEmergencySuggestionsClearRequested = () => {
    this.setState({ lMobileEmergencySuggestions: [] });
  };
  // Autosuggest will call this function every time you need to clear suggestions.
  onMedicalSocietySuggestionsClearRequested = () => {
    this.setState({ lMedicalSocietySuggestions: [] });
  };

  addContractedDevice = () => {
    const { aDevicesList } = this.state;
    aDevicesList.push({
      devicephone: "",
      show_device: "",
    });
    this.setState({ aDevicesList });
  };

  removeContractedDevice = () => {
    const { aDevicesList } = this.state;
    aDevicesList.splice(this.state.indexOfDeviceToDelete, 1);
    this.setState({ aDevicesList });
    this.closeConfirmDeleteDeviceeModal();
  };

  _handleChangeDevicePhone = (e, index) => {
    const { aDevicesList } = this.state;
    aDevicesList[index].devicephone = e.target.value;
    this.setState({ aDevicesList });
  };

  _handleChangeDeviceName = (event, ChangeEvent, index) => {
    this.setState({ oSelectedDevice: ChangeEvent.newValue });
    const { aDevicesList } = this.state;
    aDevicesList[index].show_device = ChangeEvent.newValue;
    this.setState({ aDevicesList });
  };

  openConfirmDeleteDeviceModal = (index) => {
    this.setState({
      isModalConfirmDeleteDeviceOpen: true,
      indexOfDeviceToDelete: index,
    });
  };

  closeConfirmDeleteDeviceeModal = () => {
    this.setState({
      isModalConfirmDeleteDeviceOpen: false,
      indexOfDeviceToDelete: null,
    });
  };

  downloadClientQR = async () => {
    const { oClient } = this.state;    
    window.open(`${CONSTANTS.API_URL}qrinterface?id=${oClient.id}`, "_blank");
  };

  downloadSpouseQR = async () => {
    const { oPartner } = this.state;    
    console.log(oPartner);
    window.open(`${CONSTANTS.API_URL}qrinterface?id=${oPartner.id}`, "_blank");
  };
  
  calculateAge = () => {
    const { oClient, oPartner, nPage } = this.state;
    if (nPage === 1) {
      if (oClient.birthdate)
        return Helpers.GetDateDiffNow(oClient.birthdate) + " años";
      else return "";
    } else if (oPartner.birthdate)
      return Helpers.GetDateDiffNow(oPartner.birthdate) + " años";
    else return "";
  };

  render = () => {
    const { oPartner, oPartnerErrors, oClient, oErrors, nPage, partner } =
      this.state;
      
    return oClient !== null ? (
      <Card>
        <UpdatePageTitle
          BackTo="/clients"
          saveAction={this._save}
          bAddPartner={partner}
          nPage={nPage}
          fChangePage={this.changePage}
          fClientFile={this.downloadClientFile}
          bClient={oClient.id || oPartner.id ? true : false}
        >
          Crear cliente
        </UpdatePageTitle>
        {nPage === 1
          ? this.renderClientForm(oClient, oErrors, true)
          : this.renderClientForm(oPartner, oPartnerErrors, false)}
      </Card>
    ) : null;
  };

  renderConfirmDeleteDeviceModal = () => {
    const { isModalConfirmDeleteDeviceOpen } = this.state;
    return (
      <Modal
        isOpen={isModalConfirmDeleteDeviceOpen}
        handleActionModal={this.removeContractedDevice}
        modalActionCaption={"Eliminar"}
        modalActionButtonType={"danger"}
        modalActionFaIcon={faTrash}
        handleCloseModal={this.closeConfirmDeleteDeviceeModal}
        modalTitle="Confirmar"
        customStyles={{ width: "550px" }}
      >
        <Grid.Row className="system-body">
          <Grid.Col width={12}>
            ¿Desea eliminar el dispositivo seleccionado?
          </Grid.Col>
        </Grid.Row>
      </Modal>
    );
  };

  renderDeviceSuggestion = (oDeviceSuggestion) => (
    <div className="suggestion-item">
      <span>{oDeviceSuggestion.name}</span>
      <span
        className="icon ml-auto icon-action"
        onClick={() =>
          this.openConfirmDeleteDeviceModelModal(oDeviceSuggestion.id)
        }
      >
        <Icon name="trash" />
      </span>
    </div>
  );

  renderOtherDeviceSuggestion = (oDeviceSuggestion) => (
    <div className="suggestion-item">
      <span>{oDeviceSuggestion.name}</span>
    </div>
  );

  openConfirmDeleteDeviceModelModal = (nDeviceModelId) => {
    this.setState({
      isModalConfirmDeleteDeviceModelOpen: true,
      nDeviceModelDeleteId: nDeviceModelId,
    });
  };

  // Funcion para desactivar un elemento de la lista autocompletada de aparatos
  deleteDevice = (nDeviceModelId) => {
    const { nPage, oClient, oPartner } = this.state;
    this.props.deleteDevice(nDeviceModelId);
    let oClientTemp = nPage === 1 ? { ...oClient } : { ...oPartner };
    oClientTemp.show_device = "";
    this.saveChange(oClientTemp);
    this.setState({
      isModalConfirmDeleteDeviceModelOpen: false,
      nDeviceModelDeleteId: null,
      oSelectedDevice: null,
    });
  };

  closeConfirmDeleteDeviceModelModal = () => {
    this.setState({
      isModalConfirmDeleteDeviceModelOpen: false,
      nDeviceModelDeleteId: null,
    });
  };

  renderConfirmDeleteDeviceModelModal = () => {
    const { isModalConfirmDeleteDeviceModelOpen, nDeviceModelDeleteId } =
      this.state;
    return (
      <Modal
        isOpen={isModalConfirmDeleteDeviceModelOpen}
        handleActionModal={() => this.deleteDevice(nDeviceModelDeleteId)}
        modalActionCaption={"Eliminar"}
        modalActionButtonType={"danger"}
        modalActionFaIcon={faTrash}
        handleCloseModal={this.closeConfirmDeleteDeviceModelModal}
        modalTitle="Confirmar"
        customStyles={{ width: "550px" }}
      >
        <Grid.Row className="system-body">
          <Grid.Col width={12}>
            ¿Desea eliminar el modelo de dispositivo seleccionado?
          </Grid.Col>
        </Grid.Row>
      </Modal>
    );
  };
  renderClientForm = (oUser, oErrors, bIsClient) => {
    const {
      nPage,
      phoneType,
      phone,
      email,
      lDeviceSuggestions,
      lMobileEmergencySuggestions,
      lMedicalSocietySuggestions,
      partner,
      mides,
      aDevicesList,
      bEditAddress,
      nEditIndex,
      sDomicileEdit,
      sCornerEdit,
      sCityEdit,
      sStateEdit,
      sZipCodeEdit,
      checked,
      
    } = this.state;
    const { aStates } = this.props.oStates;
    const { aCities } = this.props.oCities;
    
    if (oUser.show_medicines === undefined || oUser.show_medicines === null)
      oUser.show_medicines = "";
    if (
      oUser.show_medical_record === undefined ||
      oUser.show_medical_record === null
    )
      oUser.show_medical_record = "";
    return (
      <Card.Body>
        <Grid.Row className="calls-board-container">
          <Grid.Col width={12} md={3}>
            <Card>
              <Card.Body className="text-center">
                <img className="avata avatar-xxl" alt="Perfil" src={profile} />
                {oUser.midespartner && (
                  <h5 className="m-0">
                    <Icon name="users" className="mr-2" />
                    MIDES ({oUser.midespartner}%)
                  </h5>
                )}
                <h4 className="h3 mt-0 mb-4 mb-3">
                  {oUser.lastname ? oUser.lastname : ""}{" "}
                  {oUser.name ? oUser.secondsurname : ""}{" "}
                  {oUser.name ? oUser.name : ""}{" "}
                  {oUser.name ? oUser.secondname : ""}
                </h4>
                <h4 className="h3 mt-0 mb-4 mb-3">{oUser.code}</h4>
              </Card.Body>
            </Card>

            {bIsClient ? (
              <Button
                color="primary"
                className="d-flex m-auto center-align"
                onClick={this.downloadClientQR}
              >
                <FontAwesomeIcon
                  icon={faQrcode}
                  className="m-auto"
                ></FontAwesomeIcon>
                <span className="ml-2">Generar QR</span>
              </Button>
            ) : (
              <Button
                color="primary"
                className="d-flex m-auto center-align"
                onClick={this.downloadSpouseQR}
              >
                <FontAwesomeIcon
                  icon={faQrcode}
                  className="m-auto"
                ></FontAwesomeIcon>
                <span className="ml-2">Generar QR</span>
              </Button>
            )}
          </Grid.Col>
          <Grid.Col width={12} md={9}>
            {/* ==============================  Datos básicos ==============================   */}
            <Card statusColor="green" title="Datos básicos" isCollapsible>
              <Card.Body className="background-datosbasicos">
                <Grid.Row>
                  {/* Primer Apellido */}
                  <Grid.Col className="col-xs-12" sm={12} md={6}>
                    <Form.Group label="Primer Apellido">
                      <Form.Input
                        name="lastname"
                        placeholder="Primer Apellido"
                        value={oUser.lastname ? oUser.lastname : ""}
                        feedback={oErrors.lastname}
                        invalid={oErrors.lastname ? true : false}
                        onChange={this._handleChange}
                      />
                    </Form.Group>
                  </Grid.Col>
                  {/* Segundo Apellido */}
                  <Grid.Col className="col-xs-12" sm={12} md={6}>
                    <Form.Group label="Segundo Apellido">
                      <Form.Input
                        name="secondsurname"
                        placeholder="Segundo Apellido"
                        value={oUser.secondsurname ? oUser.secondsurname : ""}
                        onChange={this._handleChange}
                      />
                    </Form.Group>
                  </Grid.Col>
                  {/* Nombre */}
                  <Grid.Col className="col-xs-12" sm={12} md={6}>
                    <Form.Group label="Primer Nombre">
                      <Form.Input
                        name="name"
                        placeholder="Primer Nombre"
                        value={oUser.name ? oUser.name : ""}
                        onChange={this._handleChange}
                        feedback={oErrors.name}
                        invalid={oErrors.name ? true : false}
                      />
                    </Form.Group>
                  </Grid.Col>
                  {/* Segundo Nombre */}
                  <Grid.Col className="col-xs-12" sm={12} md={6}>
                    <Form.Group label="Segundo Nombre">
                      <Form.Input
                        name="secondname"
                        placeholder="Segundo Nombre"
                        value={oUser.secondname ? oUser.secondname : ""}
                        onChange={this._handleChange}
                      />
                    </Form.Group>
                  </Grid.Col>
                  {/* Sexo */}
                  <Grid.Col className="col-xs-12" sm={12} md={6}>
                    <Form.Group label="Sexo">
                      <Form.Select
                        name="sex"
                        value={oUser.sex && oUser.sex}
                        onChange={this._handleChange}
                      >
                        <option value="Mujer">Mujer</option>
                        <option value="Hombre">Hombre</option>
                      </Form.Select>
                    </Form.Group>
                  </Grid.Col>
                  {/* Cédula */}
                  <Grid.Col className="col-xs-12" sm={12} md={6}>
                    <Form.Group label="Cédula">
                      <Form.Input
                        name="identificationcard"
                        placeholder="Cédula"
                        value={
                          oUser.identificationcard
                            ? oUser.identificationcard
                            : ""
                        }
                        onChange={this._handleChange}
                        feedback={oErrors.identificationcard}
                        invalid={oErrors.identificationcard ? true : false}
                      />
                    </Form.Group>
                  </Grid.Col>
                  {/* Fecha de nacimiento */}
                  <Grid.Col className="col-xs-6" sm={6} md={4}>
                    <Form.Group label="Fecha de nacimiento">
                      <DatePicker
                        className="form-control"
                        locale={es}
                        dateFormat="dd/MM/yyyy"
                        selected={oUser.birthdate ? oUser.birthdate : ""}
                        onChange={this._handleBirthDateChange}
                        showYearDropdown
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col className="col-xs-6" sm={6} md={4}>
                    <Form.Group label="Edad">
                      <Form.Input
                        disabled
                        name="edad"
                        placeholder="Edad"
                        value={this.calculateAge()}
                        onChange={this._handleChange}
                      />
                    </Form.Group>
                  </Grid.Col>
                  {/* Conyuge */}
                  <Grid.Col sm={12} md={4} className="col-xs-12">
                    {nPage === 1 && (
                      <Grid.Row>
                        {bIsClient && (
                          <>
                            <Grid.Col className="col-xs-6">
                              <Form.Group label="&nbsp;">
                                <label className="custom-switch pt-2 mt-1">
                                  <input
                                    type="checkbox"
                                    name="partner"
                                    className="custom-switch-input"
                                    defaultChecked={oUser.spouse}
                                    value={oUser.spouse}
                                    onClick={this._handleCheckedChange}
                                  />
                                  <span className="custom-switch-indicator"></span>
                                  <span className="custom-switch-description font-weight-bold">
                                    Cónyuge
                                  </span>
                                </label>
                              </Form.Group>
                            </Grid.Col>
                            {partner ? (
                              <Grid.Col className="col-xs-6">
                                <Form.Group label="&nbsp;">
                                  <Button
                                    color="primary"
                                    onClick={this.changePage}
                                  >
                                    Datos del cónyuge
                                  </Button>
                                </Form.Group>
                              </Grid.Col>
                            ) : null}
                          </>
                        )}
                      </Grid.Row>
                    )}
                  </Grid.Col>

                  {/* Telefono */}
                  <Grid.Col sm={4} md={4} className="col-xs-12">
                    <Form.Group label="Teléfono">
                      <Form.Input
                        name="principal_phone"
                        placeholder="Teléfono principal"
                        value={
                          oUser.principal_phone ? oUser.principal_phone : ""
                        }
                        onChange={this._handleChange}
                      />
                    </Form.Group>
                  </Grid.Col>

                  {/* Otros Telefonos */}
                  <Grid.Col sm={4} md={4} className="col-xs-12">
                    <Form.Group label="Otros teléfonos">
                      <Form.InputGroup>
                        <Form.Select
                          name="phoneType"
                          value={phoneType}
                          onChange={this._handlePhoneTypeChange}
                        >
                          <option value={"Celular"}>Celular</option>
                          <option value={"Vivienda"}>Vivienda</option>
                        </Form.Select>
                        <Form.Input
                          name="phone"
                          placeholder="Teléfono"
                          value={phone}
                          onChange={(e) => {
                            this.setState({ phone: e.target.value });
                          }}
                        />
                        <Form.InputGroupAppend>
                          <Button
                            RootComponent="button"
                            color="primary"
                            onClick={this._handleAddPhone}
                          >
                            <Icon name="plus" />
                          </Button>
                        </Form.InputGroupAppend>
                      </Form.InputGroup>
                    </Form.Group>
                    {/* Lista de telefonos */}
                    {Array.isArray(oUser.phones)
                      ? oUser.phones.map((elem, index) => {
                          return (
                            <Form.Group
                              key={"phone" + index}
                              className="mb-1"
                              width={12}
                            >
                              <Form.InputGroup>
                                <Form.InputGroupPrepend>
                                  <Form.InputGroupText>
                                    {elem.type === "principal" ? (
                                      <FontAwesomeIcon
                                        icon={faHome}
                                      ></FontAwesomeIcon>
                                    ) : (
                                      <FontAwesomeIcon
                                        icon={faMobileAlt}
                                      ></FontAwesomeIcon>
                                    )}
                                  </Form.InputGroupText>
                                </Form.InputGroupPrepend>
                                <Form.Input disabled value={elem.phonenumber} />
                                <Form.InputGroupAppend>
                                  <Button
                                    RootComponent="button"
                                    color="danger"
                                    onClick={() =>
                                      this._handleRemovePhone(index)
                                    }
                                  >
                                    <Icon name="trash" />
                                  </Button>
                                </Form.InputGroupAppend>
                              </Form.InputGroup>
                            </Form.Group>
                          );
                        })
                      : ""}
                  </Grid.Col>

                  {/* Correos */}
                  <Grid.Col sm={4} md={4} className="col-xs-12">
                    <Form.Group label="Correos">
                      <Form.InputGroup>
                        <Form.Input
                          name="email"
                          value={email}
                          onChange={(e) => {
                            this.setState({ email: e.target.value });
                          }}
                        />
                        <Form.InputGroupAppend>
                          <Button
                            RootComponent="button"
                            color="primary"
                            onClick={this._handleAddEmail}
                          >
                            <Icon name="plus" />
                          </Button>
                        </Form.InputGroupAppend>
                      </Form.InputGroup>
                    </Form.Group>
                    {/* Lista de Correos */}
                    {Array.isArray(oUser.emails)
                      ? oUser.emails.map((elem, index) => {
                          return (
                            <Form.Group key={"email" + index} className="mb-1">
                              <Form.InputGroup>
                                <Form.Input disabled value={elem.email} />
                                <Form.InputGroupAppend>
                                  <Button
                                    RootComponent="button"
                                    color="danger"
                                    onClick={() =>
                                      this._handleRemoveEmail(index)
                                    }
                                  >
                                    <Icon name="trash" />
                                  </Button>
                                </Form.InputGroupAppend>
                              </Form.InputGroup>
                            </Form.Group>
                          );
                        })
                      : ""}
                  </Grid.Col>

                  {/* Domicilio */}
                  {/* TODO cambiar para que se puedan agregar mas de una direccion*/}
                  <Grid.Col width={12}>
                    <Form.Group label="Domicilio">
                      <Form.InputGroup>
                        <Form.Input
                          name="address"
                          placeholder="Domicilio"
                          value={this.state.address}
                          onChange={this._handleChangeAddress}
                          className="field-address col-xs-12"
                        />
                        <Form.Input
                          name="corner"
                          placeholder="Esquina"
                          value={this.state.corner}
                          onChange={this._handleChangeCorner}
                          className="field-address col-xs-12"
                        />

                        <Form.Select
                          name="cities"
                          className="field-city col-xs-12"
                          onChange={this._handleChangeCity}
                        >
                          <option
                            key={"cityvacio"}
                            value={"cityvacio"}
                          ></option>
                          {aCities.map((oCity) => {
                            return (
                              <option
                                key={"city" + oCity.id}
                                value={JSON.stringify(oCity)}
                              >
                                {oCity.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Select
                          name="states"
                          className="field-city col-xs-12"
                          onChange={this._handleChangeState}
                        >
                          <option
                            key={"statevacio"}
                            value={"statevacio"}
                          ></option>
                          {aStates.map((oState) => {
                            return (
                              <option
                                key={"state" + oState.id}
                                value={JSON.stringify(oState)}
                              >
                                {oState.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Input
                          name="zipcode"
                          placeholder="CP"
                          value={this.state.zipcode}
                          onChange={this._handleChangeZipCode}
                          className="field-zipcode col-xs-12"
                        />
                        <Form.InputGroupAppend className="col-xs-12">
                          <Button
                            RootComponent="button"
                            color="primary"
                            onClick={this._handleAddAddress}
                            className="col-xs-12"
                          >
                            <Icon name="plus" />
                          </Button>
                        </Form.InputGroupAppend>
                      </Form.InputGroup>
                    </Form.Group>
                    {/* Lista de Direcciones */}
                    {Array.isArray(oUser.addresses) &&
                    oUser.addresses.length > 0
                      ? oUser.addresses.map((elem, index) => {
                          if (bEditAddress && nEditIndex === index)
                            return (
                              <Form.Group key={"address" + index}>
                                <Form.InputGroup>
                                  <Form.Input
                                    name="sDomicileEdit"
                                    value={sDomicileEdit ? sDomicileEdit : ""}
                                    className="field-address col-xs-12"
                                    onChange={this._handleInputAddressEdit}
                                  />
                                  <Form.Input
                                    name="sCornerEdit"
                                    value={sCornerEdit ? sCornerEdit : ""}
                                    className="field-address col-xs-12"
                                    onChange={this._handleInputAddressEdit}
                                  />
                                  {
                                    <Form.Select
                                      name="sCityEdit"
                                      value={sCityEdit.id}
                                      className="field-city-data col-xs-12"
                                      onChange={this._handleChangeCityEdit}
                                    >
                                      <option
                                        key={"cityEditVacio"}
                                        value={"cityEditVacio"}
                                      ></option>
                                      {aCities.map((oCityEdit) => {
                                        return (
                                          <option
                                            key={"cityEdit" + oCityEdit.id}
                                            value={oCityEdit.id}
                                          >
                                            {oCityEdit.name}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                  }
                                  {
                                    <Form.Select
                                      name="sStateEdit"
                                      value={sStateEdit.id}
                                      className="field-city-data col-xs-12"
                                      onChange={this._handleChangeStateEdit}
                                    >
                                      <option
                                        key={"stateEditVacio"}
                                        value={"stateEditVacio"}
                                      ></option>
                                      {aStates.map((oStateEdit) => {
                                        return (
                                          <option
                                            key={"stateEdit" + oStateEdit.id}
                                            value={oStateEdit.id}
                                          >
                                            {oStateEdit.name}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                  }
                                  {
                                    <Form.Input
                                      disabled
                                      name="sZipCodeEdit"
                                      value={sZipCodeEdit}
                                      onChange={this._handleInputAddressEdit}
                                      className="field-zipcode col-xs-12"
                                    />
                                  }
                                  <Form.InputGroupAppend className="col-xs-12">
                                    <Button
                                      RootComponent="button"
                                      color="green"
                                      onClick={() =>
                                        this._handleConfirmEditAddress(index)
                                      }
                                      className="col-xs-12"
                                    >
                                      <Icon name="check" />
                                    </Button>
                                  </Form.InputGroupAppend>
                                </Form.InputGroup>
                              </Form.Group>
                            );
                          return (
                            <Form.Group key={"address" + index}>
                              <Form.InputGroup>
                                <Form.Input
                                  disabled
                                  value={elem.domicile}
                                  className="field-address col-xs-12"
                                />
                                <Form.Input
                                  disabled
                                  value={elem.corner1}
                                  className="field-address col-xs-12"
                                />
                                {
                                  <Form.Select
                                    disabled
                                    className="field-city-data col-xs-12"
                                  >
                                    <option selected>
                                      {elem.city && elem.city.name
                                        ? elem.city.name
                                        : ""}
                                    </option>
                                  </Form.Select>
                                }
                                {
                                  <Form.Select
                                    disabled
                                    className="field-city-data col-xs-12"
                                  >
                                    <option selected>
                                      {elem.state && elem.state.name
                                        ? elem.state.name
                                        : ""}
                                    </option>
                                  </Form.Select>
                                }
                                {
                                  <Form.Input
                                    disabled
                                    value={
                                      elem.city && elem.city.zipcode
                                        ? elem.city.zipcode
                                        : ""
                                    }
                                    className="field-zipcode col-xs-12"
                                  />
                                }
                                <Form.InputGroupAppend className="col-xs-6">
                                  <Button
                                    RootComponent="button"
                                    color="blue"
                                    onClick={() =>
                                      this._handleEditAddress(index, elem)
                                    }
                                    className="col-xs-12"
                                  >
                                    <Icon name="edit" />
                                  </Button>
                                </Form.InputGroupAppend>
                                <Form.InputGroupAppend className="col-xs-6">
                                  <Button
                                    RootComponent="button"
                                    color="danger"
                                    onClick={() =>
                                      this._handleRemoveAddress(index)
                                    }
                                    className="col-xs-12"
                                  >
                                    <Icon name="trash" />
                                  </Button>
                                </Form.InputGroupAppend>
                                &nbsp; &nbsp;
                                {elem.type === "principal" ? (
                                  <Form.InputGroupAppend className="col-xs-12">
                                    <label className="custom-switch pt-2 mt-1 text-primary">
                                      <input
                                        type="radio"
                                        checked={
                                          (elem.type === "principal" &&
                                            checked == index) ||
                                          (elem.type === "principal" &&
                                            checked == null)
                                        }
                                        value={index}
                                        onChange={this._handlePrimaryAddress}
                                      />
                                      Principal
                                    </label>
                                  </Form.InputGroupAppend>
                                ) : (
                                  <Form.InputGroupAppend className="col-xs-12">
                                    <label className="custom-switch pt-2 mt-1">
                                      <input
                                        type="radio"
                                        checked={checked == index}
                                        value={index}
                                        onChange={this._handlePrimaryAddress}
                                      />
                                      Principal
                                    </label>
                                  </Form.InputGroupAppend>
                                )}
                                {/* <Form.InputGroupAppend className="col-xs-12">  
                                <label className="custom-switch pt-2 mt-1"> 
                                <input 
                                  type="radio" 
                                  checked={checked == index }
                                  value={index}
                                  onChange={this._handlePrimaryAddress}
                                  />
                                    Principal 
                                </label>
                              </Form.InputGroupAppend> */}
                              </Form.InputGroup>
                            </Form.Group>
                          );
                        })
                      : ""}
                  </Grid.Col>
                  <Grid.Col width={12}>
                    <Form.Group label="Contactos">
                      <Form.Textarea
                        name="contacts"
                        placeholder="Contactos"
                        //value={oUser.contacts && oUser.contacts ? oUser.contacts : ""}
                        value={
                          oUser.contacts && oUser.contacts ? oUser.contacts : ""
                        }
                        onChange={this._handleChange}
                        rows={12}
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              </Card.Body>
            </Card>

            {/* ==============================  Datos medicos ==============================   */}
            <Card statusColor="red" title="Datos médicos" isCollapsible>
              <Card.Body className="background-datosmedicos">
                <Grid.Row>
                  <Grid.Col width={4}>
                    <Form.Group label="Emergencia móvil">
                      <Autosuggest
                        suggestions={lMobileEmergencySuggestions}
                        onSuggestionsFetchRequested={
                          this.onMobileEmergencySuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          this.onMobileEmergencySuggestionsClearRequested
                        }
                        getSuggestionValue={(oMobileEmergencySuggestion) =>
                          oMobileEmergencySuggestion.name
                        }
                        renderSuggestion={this.renderMobileEmergencySuggestion}
                        inputProps={{
                          className: "form-control",
                          value: oUser.mobileEmergency
                            ? oUser.mobileEmergency
                            : "",
                          onChange: this.onChangeMobileEmergency,
                        }}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={4}>
                    <Form.Group label="Sociedad médica">
                      <Autosuggest
                        suggestions={lMedicalSocietySuggestions}
                        onSuggestionsFetchRequested={
                          this.onMedicalSocietySuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          this.onMedicalSocietySuggestionsClearRequested
                        }
                        getSuggestionValue={(oMedicalSocietySuggestion) =>
                          oMedicalSocietySuggestion.name
                        }
                        renderSuggestion={this.renderMedicalSocietySuggestion}
                        inputProps={{
                          className: "form-control",
                          value: oUser.medicalSociety
                            ? oUser.medicalSociety
                            : "",
                          onChange: this.onChangeMedicalSociety,
                        }}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={4}>
                    <Form.Group label="Médico Personal">
                      <Form.InputGroup>
                        <Form.Input
                          name="show_private_doctor"
                          placeholder="Nombre Apellido - Telefono"
                          value={
                            oUser.show_private_doctor &&
                            (oUser.show_private_doctor !== null ||
                              oUser.show_private_doctor !== undefined)
                              ? oUser.show_private_doctor
                              : ""
                          }
                          onChange={this._handleChange}
                        />
                      </Form.InputGroup>
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={6}>
                    <Form.Group label="Ficha médica">
                      <Form.Textarea
                        name="show_medical_record"
                        placeholder="Ficha médica"
                        rows={4}
                        value={
                          oUser.show_medical_record
                            ? oUser.show_medical_record
                            : ""
                        }
                        onChange={this._handleChange}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={6}>
                    <Form.Group label="Medicación">
                      <Form.Textarea
                        name="show_medicines"
                        placeholder="Medicación"
                        value={oUser.show_medicines ? oUser.show_medicines : ""}
                        onChange={this._handleChange}
                        rows={4}
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              </Card.Body>
            </Card>
            {/* ==============================  Información del servicio ==============================   */}
            <Card
              statusColor="blue"
              title="Información del servicio"
              isCollapsible
            >
              <Card.Body className="background-datosaparatos">
                <Grid.Row>
                  {/* Telefono Aparato */}
                  <Grid.Col width={6}>
                    <Form.Group label="Teléfono aparato">
                      <Form.Input
                        name="devicephone"
                        placeholder="Teléfono aparato"
                        value={oUser.devicephone ? oUser.devicephone : ""}
                        onChange={this._handleChange}
                      />
                    </Form.Group>
                  </Grid.Col>

                  {bIsClient ? (
                    <>
                      <Grid.Col width={2}>
                        <Form.Group label="&nbsp;">
                          <label className="custom-switch pt-2 mt-1">
                            <input
                              type="checkbox"
                              name="contract"
                              className="custom-switch-input"
                              defaultChecked={oUser.contract}
                              value={oUser.contract}
                              onClick={(event) =>
                                this._handleCheckedChange(event)
                              }
                            />
                            <span className="custom-switch-indicator"></span>
                            <span className="custom-switch-description font-weight-bold">
                              Contrato
                            </span>
                          </label>
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col width={4}>
                        {oUser.contract ? (
                          <Form.Group label="&nbsp;">
                            <Form.Input
                              name="contractobservation"
                              placeholder="Observaciones del contrato"
                              value={
                                oUser.contractobservation
                                  ? oUser.contractobservation
                                  : ""
                              }
                              onChange={this._handleChange}
                            />
                          </Form.Group>
                        ) : (
                          ""
                        )}
                      </Grid.Col>
                    </>
                  ) : (
                    <Grid.Col width={6}></Grid.Col>
                  )}

                  <Grid.Col width={6}>
                    <Form.Group label="Aparato">
                      <Autosuggest
                        suggestions={lDeviceSuggestions}
                        onSuggestionsFetchRequested={
                          this.onDeviceSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          this.onDeviceSuggestionsClearRequested
                        }
                        getSuggestionValue={(oDeviceSuggestion) =>
                          oDeviceSuggestion.name
                        }
                        renderSuggestion={this.renderDeviceSuggestion}
                        inputProps={{
                          className: "form-control",
                          value: oUser.show_device ? oUser.show_device : "",
                          onChange: this.onChangeDevice,
                        }}
                      />
                    </Form.Group>
                  </Grid.Col>
                  {/* Fecha de afiliación */}
                  <Grid.Col width={6}>
                    <Form.Group label="Fecha de afiliación">
                      <DatePicker
                        name="affiliationdate"
                        className="form-control"
                        locale={es}
                        dateFormat="dd/MM/yyyy"
                        selected={
                          oUser.affiliationdate && oUser.affiliationdate
                        }
                        onChange={this._handleAffiliationDateChange}
                        showYearDropdown
                      />
                    </Form.Group>
                  </Grid.Col>
                  {/* Cambio de batería pulsera */}
                  <Grid.Col width={4}>
                    <Form.Group label="Cambio de batería de pulsera">
                      <DatePicker
                        name="change_battery_bracelet"
                        className="form-control"
                        locale={es}
                        dateFormat="dd/MM/yyyy"
                        selected={
                          oUser.change_battery_bracelet
                            ? oUser.change_battery_bracelet
                            : ""
                        }
                        onChange={this._handleBatteryBraceletChange}
                        showYearDropdown
                      />
                    </Form.Group>
                    
                  </Grid.Col>
                  <Grid.Col width={4}>
                    <Form.Group label="WhatsApp notificación cambio batería.">
                      <Form.Input
                        name="notification_change_battery"
                        value={oUser.notification_change_battery ? oUser.notification_change_battery : ""}
                        onChange={this._handleChange}
                        placeholder=""
                      />
                    </Form.Group>
                  </Grid.Col>
                  {/* Cambio de batería aparato */}
                  <Grid.Col width={4}>
                    <Form.Group label="Cambio de batería aparato">
                      <Form.Input
                        name="change_battery"
                        value={oUser.change_battery ? oUser.change_battery : ""}
                        onChange={this._handleChangeFeevalue}
                        placeholder="2020"
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              </Card.Body>
            </Card>

            {/* ==============================  Otros dispositivos contratados ==============================   */}
            <Grid.Row>
              <Grid.Col
                width={12}
                className={"d-flex justify-content-between mb-3"}
              >
                <h4>Otros aparatos</h4>
                <Button
                  icon="plus"
                  color={"primary"}
                  onClick={this.addContractedDevice}
                >
                  Agregar aparato
                </Button>
              </Grid.Col>
            </Grid.Row>
            {aDevicesList &&
              aDevicesList.map((oDevice, index) => {
                return (
                  <Card
                    statusColor="blue"
                    title="Aparato adicional"
                    // isCollapsible
                    options={
                      <Button
                        icon="trash"
                        color={"danger"}
                        // onClick={() => this.removeContractedDevice(index)}
                        onClick={() => this.openConfirmDeleteDeviceModal(index)}
                      >
                        ELiminar
                      </Button>
                    }
                  >
                    <Card.Body className="background-datosaparatos">
                      <Grid.Row key={index}>
                        <Grid.Col width={6}>
                          <Form.Group label="Teléfono aparato">
                            <Form.Input
                              name="devicephone"
                              placeholder="Teléfono aparato"
                              value={oDevice.devicephone}
                              onChange={(e) =>
                                this._handleChangeDevicePhone(e, index)
                              }
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col width={6}>
                          <Form.Group label="Aparato">
                            <Autosuggest
                              suggestions={lDeviceSuggestions}
                              onSuggestionsFetchRequested={
                                this.onDeviceSuggestionsFetchRequested
                              }
                              onSuggestionsClearRequested={
                                this.onDeviceSuggestionsClearRequested
                              }
                              getSuggestionValue={(oDeviceSuggestion) =>
                                oDeviceSuggestion.name
                              }
                              renderSuggestion={
                                this.renderOtherDeviceSuggestion
                              }
                              inputProps={{
                                className: "form-control",
                                value: oDevice.show_device
                                  ? oDevice.show_device
                                  : "",
                                onChange: (e, ChangeEvent) =>
                                  this._handleChangeDeviceName(
                                    e,
                                    ChangeEvent,
                                    index
                                  ),
                              }}
                            />
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>
                    </Card.Body>
                  </Card>
                );
              })}
            {nPage === 1 ? (
              <Card statusColor="red" title="Pagos" isCollapsible>
                <Card.Body className="background-datospagos">
                  <Grid.Row>
                    <Grid.Col width={3}>
                      <Form.Group label="Cuota actual">
                        <Form.Input
                          disabled={oUser.id ? true : false}
                          name="feevalue"
                          value={oUser.feevalue ? oUser.feevalue : ""}
                          onChange={this._handleChangeFeevalue}
                          placeholder="0.000"
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={3}>
                      <Form.Group label="Modo de pago">
                        <Form.Select
                          disabled={oUser.id ? true : false}
                          name="pay_method"
                          value={oUser.pay_method}
                          onChange={this._handleChange}
                        >
                          <option value={""}></option>
                          <option value={"Abitab/RedPagos"}>
                            Abitab / Red pagos
                          </option>
                          <option value={"Transferencia"}>Transferencia</option>
                          <option value={"Sistarbank"}>Sistarbanc</option>
                          <option value={"Facilpago"}>
                            Tarjeta de crédito
                          </option>
                          <option value={"PagoManual"}>Pago manual</option>
                          <option value={"NoPaga"}>No Paga</option>
                        </Form.Select>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={3}>
                      <Form.Group label="Débito">
                        {oUser.id ? (
                          <Form.Input
                            disabled
                            name="card_branch"
                            placeholder=""
                            value={oUser.card_branch}
                            onChange={this._handleChange}
                          />
                        ) : (
                          <Form.Input
                            disabled={
                              oUser.pay_method !== "Facilpago" ? true : false
                            }
                            name="card_branch"
                            placeholder=""
                            value={
                              oUser.pay_method !== "Facilpago"
                                ? ""
                                : oUser.card_branch
                            }
                            onChange={this._handleChange}
                          />
                        )}
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={3}>
                      <Form.Group label="Nro Tarjeta">
                        <Form.InputGroup>
                          {oUser.id ? (
                            <Form.Input
                              disabled
                              name="card_number"
                              placeholder="****************"
                              value={Helpers.hideCard(oUser.card_number)}
                              onChange={this._handleChange}
                            />
                          ) : (
                            <Form.Input
                              disabled={
                                oUser.pay_method !== "Facilpago" ? true : false
                              }
                              name="card_number"
                              placeholder="****************"
                              value={
                                oUser.pay_method !== "Facilpago"
                                  ? ""
                                  : oUser.card_number
                              }
                              onChange={this._handleChange}
                            />
                          )}
                        </Form.InputGroup>
                      </Form.Group>
                    </Grid.Col>
                    {nPage === 1 ? (
                      <>
                        <Grid.Col width={3}>
                          <Form.Group label="&nbsp;">
                            <label className="custom-switch pt-2 mt-1">
                              <input
                                type="checkbox"
                                name="mides"
                                className="custom-switch-input"
                                defaultChecked={oUser.midespartner}
                                value={oUser.midespartner}
                                onClick={this._handleCheckedChange}
                              />
                              <span className="custom-switch-indicator"></span>
                              <span className="custom-switch-description font-weight-bold">
                                Mides
                              </span>
                            </label>
                          </Form.Group>
                        </Grid.Col>
                        {oUser.midespartner && (
                          <Grid.Col width={3}>
                            <Form.Group label="% mides">
                              <Form.Select
                                name="midespartner"
                                value={oUser.midespartner}
                                onChange={this._handleChange}
                              >
                                <option value={100}>100%</option>
                                <option value={67}>67%</option>
                                <option value={33}>33%</option>
                                <option value={0}>0%</option>
                              </Form.Select>
                            </Form.Group>
                          </Grid.Col>
                        )}
                      </>
                    ) : null}
                  </Grid.Row>
                </Card.Body>
              </Card>
            ) : null}
            {bIsClient ? (
              <Card statusColor="teal" title="Empresa" isCollapsible>
                <Card.Body className="background-datosempresa">
                  <Grid.Row>
                    {/* Empresa */}
                    <Grid.Col width={3}>
                      <Form.Group label="&nbsp;">
                        <label className="custom-switch pt-2 mt-1">
                          <input
                            key="bussinesClient"
                            id="business"
                            type="checkbox"
                            name="business"
                            className="custom-switch-input"
                            defaultChecked={oUser.business}
                            value={oUser.business}
                            onClick={this._handleCheckedChange}
                          />
                          <span className="custom-switch-indicator"></span>
                          <span className="custom-switch-description font-weight-bold">
                            Empresa
                          </span>
                        </label>
                      </Form.Group>
                    </Grid.Col>
                    {oUser.business == true && (
                      <>
                        <Grid.Col width={3}>
                          <Form.Group label="RUT">
                            <Form.Input
                              name="companyRut"
                              placeholder="RUT"
                              value={oUser.company.rut}
                              onChange={this._handleSaveCompany}
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col width={3}>
                          <Form.Group label="Razón Social">
                            <Form.Input
                              name="companyName"
                              placeholder="Razón Social"
                              value={oUser.company.name}
                              onChange={this._handleSaveCompany}
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col width={3}>
                          <Form.Group label="Domicilio">
                            <Form.Input
                              name="companyDomicile"
                              placeholder="Domicilio"
                              value={oUser.company.domicile}
                              onChange={this._handleSaveCompany}
                            />
                          </Form.Group>
                        </Grid.Col>
                      </>
                    )}
                  </Grid.Row>
                </Card.Body>
              </Card>
            ) : (
              ""
            )}
            <Grid.Row>
              {/* Observaciones */}
              <Grid.Col width={12}>
                <Grid.Row>
                  <Grid.Col width={12}>
                    <Form.Group label="Observaciones">
                      <Form.Textarea
                        name="observation"
                        placeholder="Observaciones"
                        value={
                          oUser.observation && oUser.observation !== null
                            ? oUser.observation
                            : ""
                        }
                        onChange={this._handleChange}
                        rows={4}
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col width={4}>
            <Grid.Row></Grid.Row>
          </Grid.Col>
        </Grid.Row>
        {this.renderConfirmDeleteDeviceModal()}
        {this.renderConfirmDeleteDeviceModelModal()}
      </Card.Body>
    );
  };
}

const mapStateToProps = (state) => ({
  oShared: state.sharedReducer,
  oClients: state.clientsReducer,
  oStates: state.statesReducer,
  oCities: state.citiesReducer,
  oDevices: state.devicesReducer,
  oMobileEmergencies: state.mobileEmergenciesReducer,
  oMedicalSocieties: state.medicalSocietiesReducer,
  propsClient: state.clientsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  get: (idClient) => {
    dispatch(actionGetClient(idClient));
  },
  reset: () => {
    dispatch(actionResetClients());
  },

  getClient: () => {
    dispatch(actionGetClients());
  },

  getStates: () => {
    dispatch(actionGetStates());
  },
  getCities: (nStateId) => {
    dispatch(actionGetCities(nStateId));
  },
  getDevices: () => {
    dispatch(actionGetDevices());
  },
  getMobileEmergencies: () => {
    dispatch(actionGetMobileEmergencies());
  },
  getMedicalSocieties: () => {
    dispatch(actionGetMedicalSocieties());
  },
  deleteDevice: (nDeviceId) => {
    dispatch(actionDeleteDevice(nDeviceId));
  },
  save: (oClient) => {
    dispatch(actionSaveClient(oClient));
  },
  update: (oClient) => {
    dispatch(actionUpdateClient(oClient));
  },
  cleanClient: () => {
    dispatch(actionCleanClient());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientUpdate);
